export default {
  data() {
    return {
      changeNo: '',
      orderNo: '',
    }
  },
  components: {},
  created() {
  },
  mounted() {
  },
  activated() {
    this.changeNo = this.$route.query.changeNo || '';
    this.orderNo = this.$route.query.orderNo || '';
  },
  deactivated() {
  },
  destroyed() {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    goDetail() {
      if (this.changeNo) {
        this.$router.replace({
          name: 'admin-my-order-train-change-detail',
          query: {
            changeNo: this.changeNo,
            orderNo: this.orderNo,
            status: 3,
            jumpType: 'list',
          }
        });
      }
      if (this.orderNo) {
        this.$router.replace({
          name: 'admin-my-order-train-detail',
          query: {
            orderNo: this.orderNo,
          }
        });
      }
    },
  }
}
